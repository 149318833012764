.searchBarContainer{
  display: grid;
  grid-template-columns: repeat(8,minmax(0,var(--size-row))) 1fr ;
}

.searchBar{
  display: flex;
  flex-flow: column wrap;
  padding: var(--spacing-40) var(--spacing-80) var(--spacing-80);
  grid-column: 1 / 9;
  overflow: hidden;
}

.emptyLink {
  display: block;
  font: var(--font-p2-desktop-default);
  text-decoration: underline;
  grid-column: 1/3;
}

.dark .emptyLink {
  color: var(--blue);
}

.light .emptyLink {
  color: var(--grey);
}


@media (max-width: 768px){
  .searchBarContainer{
    grid-column: full-start / full-end;
    grid-template-columns: 1fr;
    grid-row: 2;
  }

  .searchBar{
    grid-column: 1;
    padding: var(--spacing-24) var(--spacing-40) var(--spacing-40);
  }
}

@media (max-width: 608px) {
  .searchBarContainer{
    grid-column: full-start / full-end;
    grid-row: 2;
  }

  .searchBar{
    padding: var(--spacing-24) var(--spacing-24) var(--spacing-40);
  }
}
